<template>
  <div class="platter question">
    <vc-loading v-if="loading">Loading Question...</vc-loading>
    <template v-else>
      <h3 class="mb-3">
        <strong>
          {{ question.text }}
        </strong>
      </h3>

      <b-button
        v-for="c in question.choices"
        :key="c.id"
        block
        variant="outline-primary"
        @click="$emit('answer', c.id)"
      >
        {{ c.text }}
      </b-button>

      <b-button
        variant="primary"
        v-if="showBack"
        class="mt-4"
        size="sm"
        @click="$emit('back')"
      >
        <font-awesome-icon :icon="'arrow-left'" />
        &nbsp;
        <strong>Go Back</strong>
      </b-button>
    </template>
  </div>
</template>

<script>
export default {
  name: "VendorQuestion",
  props: {
    question: { type: Object, required: true },
    loading: { type: Boolean },
    showBack: { type: Boolean }
  },
  computed: {}
};
</script>

<style scoped lang="scss">
.question {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
</style>