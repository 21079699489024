<template>
  <div v-if="show" class="platter light p-3">
    <h4 class="accent">Sort By</h4>
    <ul class="list-unstyled mb-0">
      <li v-for="m in modes" :key="m.key">
        <a
          href="#"
          :class="{ active: mode === m.key }"
          @click.prevent="$emit('change', m.key)"
        >
          {{ m.label }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import Enumerable from "linq";

export default {
  name: "VendorsSort",
  computed: {
    show() {
      const s = this.showRank || this.showDateAdded;
      if (s) this.$emit("showing");
      return s;
    },
    modes() {
      const modeList = [
        {
          label: "Rank",
          key: "rank",
          active: this.showRank
        },
        {
          label: "Name",
          key: "name",
          active: true
        },
        {
          label: "Date Added",
          key: "date-added",
          active: this.showDateAdded
        }
      ];

      return Enumerable.from(modeList)
        .where(i => i.active)
        .toArray();
    }
  },
  watch: {
    show() {
      if (this.show) this.$emit("showing");
    }
  },
  props: {
    mode: { type: String },
    showRank: { type: Boolean },
    showDateAdded: { type: Boolean }
  }
};
</script>
