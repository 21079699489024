var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vendor-list-item"},[_c('b-link',{staticClass:"d-block mb-3",attrs:{"to":{
      name: 'Vendor',
      params: {
        vendorKey: _vm.vendor.key,
        vendorList: this.vendors,
        routeData: this.routeData
      }
    }}},[_c('div',{class:_vm.platterCssClasses},[(_vm.vendor.score)?_c('b-avatar',{staticClass:"float-right",staticStyle:{"margin-top":"-5px","margin-right":"-5px"},attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.vendor.score)+" ")]):_c('vendor-favorite-toggle',{staticClass:"float-right",attrs:{"vendor":_vm.vendor}}),_c('div',{staticClass:"d-block d-md-flex justify-items-center align-items-center"},[_c('div',{staticClass:"logo mr-3 mb-2 mb-sm-0"},[_c('b-img-lazy',{attrs:{"src":_vm.vendor.logoSrc}})],1),_c('div',[(_vm.vendor.topPick)?_c('b-badge',{staticClass:"top-pick mb-2",attrs:{"variant":"primary"}},[_c('font-awesome-icon',{attrs:{"icon":"star"}}),_vm._v(" Top Pick ")],1):_vm._e(),_c('h2',{staticClass:"d-none d-md-block"},[_vm._v(" "+_vm._s(_vm.vendor.name)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.vendor.shortDesc)+" ")]),_c('vc-vendor-badges',{staticClass:"mb-0 mt-1",attrs:{"vendor":_vm.vendor}})],1)])],1),(_vm.vendor.pickReason)?_c('div',{staticClass:"platter reason mb-0 mt-0 py-3"},[_c('div',{staticClass:"d-block d-md-flex justify-items-center align-items-center"},[_c('div',{staticClass:"logo mr-3 text-right text-muted"}),_c('div',[_c('h6',{staticClass:"mb-1 text-muted"},[_c('small',[_c('strong',[_vm._v("Why Is This Recommended?")])])]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.vendor.pickReason))])])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }