<template>
  <div id="vendors-list">
    <div v-if="useGroups" class="grouped">
      <div v-for="g in vendorsByGroup" :key="g.name" class="pb-3 pb-sm-3">
        <h4 v-if="g.name" class="group-title accent pt-1 px-1 pb-1">
          {{ g.name }}
        </h4>
        <div style="margin-top: -5px;">
          <vendors-list-item
            v-for="v in filterVendors(g.items)"
            :key="v.id"
            :vendor="v"
            :vendors="vendors"
            :routeData="routeData"
          />
        </div>
        <div
          v-if="g.items.length > filterVendors(g.items).length"
          class="text-center"
        >
          <b-link @click="$emit('show-all')" class="text-muted">
            Show {{ g.items.length - filterVendors(g.items).length }} More in
            {{ g.name }}...
          </b-link>
        </div>
      </div>
    </div>
    <div v-else>
      <vendors-list-item
        v-for="v in filterVendors(vendors)"
        :key="v.id"
        :vendor="v"
        :vendors="vendors"
        :routeData="routeData"
      />
    </div>
    <div v-if="!hasVendors" class="platter light">
      <h3 class="text-muted text-center">
        No vendors to display
      </h3>
    </div>
  </div>
</template>

<script>
import VendorsListItem from "./VendorsListItem";

import Enumerable from "linq";

export default {
  name: "VendorsList",
  components: { VendorsListItem },
  computed: {
    vendorsByGroup() {
      const g = Enumerable.from(this.vendors)
        .groupBy(v => v.group)
        .select(i => {
          return { name: i.key(), items: i.toArray() };
        })
        .toArray();
      return g;
    },
    hasVendors() {
      return (this.vendors?.length ?? 0) > 0;
    }
  },
  methods: {
    filterVendors(vendors) {
      if (!this.topPicks) return vendors;
      return vendors.filter(v => v.topPick);
    }
  },
  props: {
    vendors: { type: Array, required: true },
    useGroups: { type: Boolean },
    topPicks: { type: Boolean },
    routeData: { type: Object }
  }
};
</script>

<style scoped lang="scss">
.group-title {
  position: sticky;
  top: 65px;
  z-index: 100;
  backdrop-filter: blur(15px);

  @media (min-width: 576px) {
    top: 72px;
  }
}
</style>