<template>
  <b-row v-if="text" class="mt-2 mt-sm-0">
    <b-col cols="12" sm="9" order="1">
      <h1 class="mb-2">
        <font-awesome-icon v-if="icon" :icon="icon" />
        {{ text }}
      </h1>
    </b-col>
    <b-col cols="12" sm="3" order="2"></b-col>
  </b-row>
</template>

<script>
export default {
  name: "VendorsTitle",
  props: {
    icon: { type: String },
    text: { type: String }
  }
};
</script>

<style scoped lang="scss">
h1 {
  font-weight: 900;
  font-size: 2em;

  @media (min-width: 576px) {
    font-size: 2.7em;
  }
}
</style>