<template>
  <div class="vendor-list-item">
    <b-link
      :to="{
        name: 'Vendor',
        params: {
          vendorKey: vendor.key,
          vendorList: this.vendors,
          routeData: this.routeData
        }
      }"
      class="d-block mb-3"
    >
      <div :class="platterCssClasses">
        <b-avatar
          v-if="vendor.score"
          variant="primary"
          class="float-right"
          style="margin-top: -5px; margin-right: -5px;"
        >
          {{ vendor.score }}
        </b-avatar>
        <vendor-favorite-toggle v-else class="float-right" :vendor="vendor" />
        <div class="d-block d-md-flex justify-items-center align-items-center">
          <div class="logo mr-3 mb-2 mb-sm-0">
            <b-img-lazy :src="vendor.logoSrc" />
          </div>
          <div>
            <b-badge
              v-if="vendor.topPick"
              class="top-pick mb-2"
              variant="primary"
            >
              <font-awesome-icon icon="star" />
              Top Pick
            </b-badge>

            <h2 class="d-none d-md-block">
              {{ vendor.name }}
            </h2>
            <p>
              {{ vendor.shortDesc }}
            </p>
            <vc-vendor-badges :vendor="vendor" class="mb-0 mt-1" />
          </div>
        </div>
      </div>
      <div v-if="vendor.pickReason" class="platter reason mb-0 mt-0 py-3">
        <div class="d-block d-md-flex justify-items-center align-items-center">
          <div class="logo mr-3 text-right text-muted"></div>
          <div>
            <h6 class="mb-1 text-muted">
              <small><strong>Why Is This Recommended?</strong></small>
            </h6>
            <p class="mb-0">{{ vendor.pickReason }}</p>
          </div>
        </div>
      </div>
    </b-link>
  </div>
</template>

<script>
const VendorFavoriteToggle = () =>
  import(
    /* webpackChunkName: "favoriteToggle" */ "../Vendor/VendorFavoriteToggle.vue"
  );

export default {
  name: "VendorsListItem",
  components: {
    VendorFavoriteToggle
  },
  props: {
    vendor: { type: Object, required: true },
    vendors: { type: Array },
    routeData: { type: Object }
  },
  computed: {
    platterCssClasses() {
      return (
        "platter vendor " + (this.vendor.pickReason ? "has-reason pb-3" : "")
      );
    }
  }
};
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
}

h2 {
  font-size: 1.6em;
  font-weight: bold;
}

.platter.vendor {
  a:hover & {
    transition: all 0.2s;
  }

  p {
    font-size: 0.95em;
    margin-bottom: 0;
  }

  &.has-reason {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    a:hover & {
      border-bottom-color: transparent;
    }
  }
}

.logo {
  width: 150px;
  min-width: 150px;
}

img {
  width: 100%;
}

.reason {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-color: transparent;
  font-size: 0.95em;

  strong {
    font-weight: 900;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  a:hover & {
    border-top-color: transparent;
  }
}

.top-pick {
  font-size: 0.95em;
}

.favorite-toggle {
  margin-top: -10px;
  margin-right: -10px;
}
</style>