<template>
  <div v-if="show" class="platter light p-3">
    <b-form-checkbox
      v-if="showGroupResults"
      :checked="groupResults"
      @change="$emit('change-group-results', $event)"
      switch
      class="mb-1"
    >
      Group Results
    </b-form-checkbox>
    <b-form-checkbox
      v-if="showTopPicksOnly"
      :checked="topPicksOnly"
      @change="$emit('change-top-picks-only', $event)"
      switch
    >
      Top Picks Only
    </b-form-checkbox>
  </div>
</template>

<script>
export default {
  name: "VendorsViewOptions",
  computed: {
    show() {
      const s = this.showGroupResults || this.showTopPicksOnly;
      if (s) this.$emit("showing");
      return s;
    }
  },
  props: {
    showGroupResults: { type: Boolean },
    showTopPicksOnly: { type: Boolean },
    groupResults: { type: Boolean },
    topPicksOnly: { type: Boolean }
  }
};
</script>
