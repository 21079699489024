<template>
  <div id="collection">
    <vc-app-header />

    <b-row class="align-items-center mb-4">
      <b-col cols="12" :sm="isSearch || showRestartQuestions ? 9 : 12">
        <vendors-title :text="titleText" :icon="titleIcon" />

        <b-form action="." @submit.prevent="doSearch">
          <b-input
            v-if="isSearch"
            type="search"
            size="lg"
            class="mb-0"
            ref="searchField"
            v-model="localQuery"
          />
        </b-form>
      </b-col>
      <b-col v-if="showRestartQuestions" cols="12" sm="3">
        <b-button
          size="sm"
          variant="outline-dark"
          @click="vendors = undefined"
          block
          class="d-none d-sm-inline"
        >
          Restart Questions...
        </b-button>
      </b-col>
    </b-row>

    <template v-if="currentQuestion && !vendors">
      <vendor-question
        :question="currentQuestion"
        :show-back="questions.length > 1"
        :loading="!loaded"
        @back="goBackQuestion"
        @answer="answerQuestion($event)"
      />

      <vc-loading v-if="errorMessage" has-error class="p-4">
        {{ errorMessage }}
      </vc-loading>
    </template>

    <b-row v-else>
      <b-col
        v-if="showSidebar"
        cols="12"
        sm="3"
        class="sidebar-filters"
        order-sm="2"
      >
        <div class="d-sm-none mb-3">
          <b-button
            size="sm"
            variant="outline-dark"
            @click="vendors = undefined"
            class="d-sm-none mr-2"
            v-if="currentQuestion && vendors"
          >
            Restart Questions...
          </b-button>
          <b-button
            v-if="hasViewOptions"
            size="sm"
            @click="sidebarOnMobile = !sidebarOnMobile"
            :variant="sidebarOnMobile ? 'dark' : 'outline-dark'"
          >
            View Options
            <font-awesome-icon :icon="'filter'" />
          </b-button>
        </div>

        <div
          :class="
            'sidebar-container ' + (sidebarOnMobile ? '' : 'd-none d-sm-block')
          "
        >
          <vendors-view-options
            :show-group-results="hasGroups"
            :show-top-picks-only="hasTopPicks"
            :group-results="useGroups"
            :top-picks-only="useTopPicks"
            @change-group-results="
              $router.replace({
                name: $route.name,
                params: {
                  ...$route.params
                },
                query: {
                  ...$route.query,
                  group: $event ? undefined : 'false'
                }
              })
            "
            @change-top-picks-only="
              $router.replace({
                name: $route.name,
                params: {
                  ...$route.params
                },
                query: {
                  ...$route.query,
                  topPicks: $event ? undefined : 'false'
                }
              })
            "
            @showing="hasViewOptions = true"
            class="mb-3"
          />
          <vendors-sort
            :mode="sort"
            :show-date-added="hasDateAdded"
            :show-rank="hasRank"
            @change="
              $router.replace({
                name: $route.name,
                params: { ...$route.params },
                query: {
                  ...$route.query,
                  sort: $event === 'name' ? undefined : $event
                }
              })
            "
            @showing="hasViewOptions = true"
            class="mb-3"
          />
          <vendors-cat-filter
            :vendors="vendors"
            :current="filterCategory"
            :category="category"
            @change="
              $router.replace({
                name: $route.name,
                params: { ...$route.params },
                query: { ...$route.query, filterCategory: $event }
              })
            "
            @showing="hasViewOptions = true"
            class="mb-3"
          />
        </div>
      </b-col>
      <b-col cols="12" sm="9" order-sm="1">
        <div v-if="hasCategories" class="mb-3">
          <ul class="list-inline mb-0">
            <li>
              <h6 class="accent"><strong>Categories:</strong></h6>
            </li>
            <li v-for="c in categories" class="list-inline-item" :key="c.name">
              <b-button
                :to="'/category/' + c.key"
                pill
                variant="outline-dark"
                size="sm"
                class="mb-2"
              >
                {{ c.name }}
                <font-awesome-icon icon="chevron-right" />
              </b-button>
            </li>
          </ul>
        </div>

        <template v-if="loaded && isSearch && !query"></template>
        <vendors-list
          v-else-if="loaded && !errorMessage"
          :vendors="vendorsDisplay"
          :routeData="routeData"
          :use-groups="hasGroups && useGroups"
          :top-picks="hasTopPicks && useTopPicks"
          @show-all="
            $router.replace({
              name: $route.name,
              params: {
                ...$route.params
              },
              query: {
                ...$route.query,
                topPicks: 'false'
              }
            })
          "
        />
        <vc-loading v-else-if="loaded" has-error>
          {{ errorMessage }}
        </vc-loading>
        <vc-loading v-else>Loading Vendors...</vc-loading>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VendorsList from "@/components/Vendors/VendorsList";
import VendorsSort from "@/components/Vendors/VendorsSort";
import VendorsTitle from "@/components/Vendors/VendorsTitle";
import VendorsViewOptions from "@/components/Vendors/VendorsViewOptions";
import VendorsCatFilter from "@/components/Vendors/VendorsCatFilter";
import VendorQuestion from "@/components/Vendors/VendorQuestion";

import Enumerable from "linq";

export default {
  name: "Vendors",
  components: {
    VendorsList,
    VendorsSort,
    VendorsTitle,
    VendorsViewOptions,
    VendorsCatFilter,
    VendorQuestion
  },
  props: {
    collectionKey: { type: String },
    categoryKey: { type: String },
    query: { type: String },
    isSearch: { type: Boolean },
    sort: { type: String, default: "name" },
    filterCategory: { type: String },
    group: { type: String },
    topPicks: { type: String }
  },
  data() {
    return {
      collection: undefined,
      category: undefined,
      categories: undefined,
      vendors: undefined,
      loaded: false,
      questions: [],
      answers: {},
      sidebarOnMobile: false,
      localQuery: undefined,
      hasViewOptions: false,
      errorMessage: undefined
    };
  },
  computed: {
    showRestartQuestions() {
      return this.currentQuestion && this.vendors;
    },
    useGroups() {
      return this.group !== "false";
    },
    useTopPicks() {
      return this.topPicks !== "false";
    },
    hasResults() {
      return this.vendors && this.vendors.length > 0;
    },
    vendorsDisplay() {
      let ve = Enumerable.from(this.vendors);

      if (this.filterCategory) {
        ve = ve.where(v =>
          Enumerable.from(v.categories)
            .select(i => i.toLowerCase().replaceAll(" ", "-"))
            .any(i => i === this.filterCategory)
        );
      }

      switch (this.sort) {
        case "rank":
          ve = ve
            .orderBy(v => (this.useGroups ? v.group : undefined))
            .thenByDescending(v => v.rank);
          break;
        case "date-added":
          ve = ve
            .orderBy(v => (this.useGroups ? v.group : undefined))
            .thenBy(v => v.dateAdded);
          break;
        default:
          ve = ve
            .orderBy(v => (this.useGroups ? v.group : undefined))
            .thenBy(v => v.name.toLowerCase().replace("the ", ""));
          break;
      }

      return ve.toArray();
    },
    hasGroups() {
      return !!this.vendors?.find(v => v.group);
    },
    hasTopPicks() {
      return !!this.vendors?.find(v => v.topPick);
    },
    hasRank() {
      return !!this.vendors?.find(v => v.score);
    },
    hasDateAdded() {
      return !!this.vendors?.find(v => v.dateAdded);
    },
    titleObject() {
      if (this.isSearch) {
        return { icon: "search", name: "Search Vendors" };
      }
      return this.category ? this.category : this.collection;
    },
    titleIcon() {
      return this.titleObject?.icon;
    },
    titleText() {
      return this.titleObject?.name;
    },
    showSidebar() {
      return this.loaded && (this.vendors?.length ?? 0) > 1;
    },
    currentQuestion() {
      return Enumerable.from(this.questions).lastOrDefault();
    },
    hasCategories() {
      return !!this.categories?.length;
    },
    routeData() {
      return {
        fullPath: this.$route.fullPath,
        name: this.$route.name
      };
    }
  },
  watch: {
    collectionKey() {
      this.fetchData();
    },
    categoryKey() {
      this.fetchData();
    },
    query() {
      this.fetchData();
    },
    hasRank() {
      if (!this.sort && this.hasRank)
        this.$router.replace({
          name: this.$route.name,
          params: { ...this.$route.params },
          query: { ...this.$route.query, sort: "rank" }
        });
    },
    loaded() {
      if (this.loaded) {
        this.handlePageTitle();
        this.$root.$emit("triggerScroll");
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loaded = false;
      this.hasViewOptions = false;
      this.errorMessage = undefined;

      this.categories = undefined;
      this.category = undefined;
      this.collection = undefined;
      this.vendors = undefined;
      this.questions = [];
      this.answers = {};

      this.fetchCollectionData();
      this.fetchCategoryData();
      this.fetchQueryData();
    },
    fetchCollectionData() {
      if (!this.collectionKey) return;

      this.$store
        .dispatch("fetchCollectionStart", this.collectionKey)
        .then(
          cd => {
            this.collection = cd.collection;
            this.vendors = cd.vendors;
            this.questions = [cd.question];

            if (this.$store.state.collectionVendors[this.collectionKey]) {
              this.vendors = this.$store.state.collectionVendors[
                this.collectionKey
              ];
            }
          },
          e => (this.errorMessage = e.message)
        )
        .finally(() => {
          this.loaded = true;
        });
    },
    fetchCategoryData() {
      if (!this.categoryKey) return;

      const vendorsPromise = this.$store.dispatch(
        "fetchVendorsForCategoryKey",
        this.categoryKey
      );
      const categoryPromise = this.$store.dispatch(
        "fetchCategoryByKey",
        this.categoryKey
      );

      categoryPromise.then(c => {
        this.category = c;
      });
      vendorsPromise.then(
        v => (this.vendors = v),
        e => (this.errorMessage = e.message)
      );

      Promise.allSettled([vendorsPromise, categoryPromise]).finally(() => {
        this.loaded = true;
      });
    },
    fetchQueryData() {
      if (!this.isSearch) return;

      if (!this.query) {
        this.$refs.searchField.focus();
      }

      this.localQuery = this.query;

      this.$store
        .dispatch("fetchSearch", { query: this.query })
        .then(
          r => {
            this.vendors = r.vendors;
            this.categories = r.categories;
          },
          e => (this.errorMessage = e.message)
        )
        .finally(() => {
          this.loaded = true;
        });
    },
    answerQuestion(answer) {
      this.answers[this.currentQuestion.id] = answer;

      this.loaded = false;

      this.$store
        .dispatch("submitCollectionAnswers", {
          collectionKey: this.collectionKey,
          answers: this.answers
        })
        .then(
          cd => {
            if (cd.question) {
              this.questions.push(cd.question);
            } else {
              this.vendors = cd.vendors;
              this.questions = [Enumerable.from(this.questions).first()];
              this.answers = {};
            }
          },
          e => {
            this.errorMessage = e.message;
          }
        )
        .finally(() => (this.loaded = true));
    },
    goBackQuestion() {
      this.questions.pop();
      delete this.answers[this.currentQuestion.id];
    },
    doSearch() {
      this.$router.push(`/search/${this.localQuery}`);
    },
    handlePageTitle() {
      const appName = this.$store.getters.appName;
      if (this.isSearch) {
        if (this.query) {
          document.title = `Search for '${this.query}' | ${appName}`;
        } else {
          document.title = `Search | ${appName}`;
        }
      } else if (this.collection) {
        document.title = `${this.collection.name} | ${appName}`;
      } else if (this.category) {
        document.title = `${this.category.name} | ${appName}`;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.sidebar-filters {
  font-size: 90%;

  ::v-deep a {
    text-decoration: none;

    &.active {
      font-weight: 900;
    }
  }

  ::v-deep h4 {
    font-size: 0.95em;
  }
}

@media (min-width: 576px) {
  .sidebar-container {
    position: sticky;
    top: 100px;
  }
}
</style>